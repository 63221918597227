import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import "firebase/compat/auth";

const config = {
  //apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  //authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  //databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  //projectId: "bingoapp-e6e57",
  //storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  //messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
  apiKey: "AIzaSyDilTzP1fwh406VPDT1hKFlWK7CFGZr-PA",
  authDomain: "bingoapp-e6e57.firebaseapp.com",
  projectId: "bingoapp-e6e57",
  storageBucket: "bingoapp-e6e57.appspot.com",
  messagingSenderId: "1051553832315",
  appId: "1:1051553832315:web:163cbecc0f2d28445fb1de",
  measurementId: "G-SD4VE8JKTZ"
};

firebase.initializeApp(config);

// Initialize Firebase Authentication and get a reference to the service
//const auth = firebase.auth();

export default firebase;
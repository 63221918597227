import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/app';
import * as serviceWorker from './serviceWorker';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
root.render(<App />);

serviceWorker.unregister();
